<template>
  <div ref="chatInput" class="chat-input">
    <div class="input-container">
      <van-field
        v-model="value"
        type="text"
        placeholder="请输入聊天内容"
        @keyup.enter="send"
        @focus="onFocus"
        @blur="onBlur"
      />
      <img class="img" src="./images/add.png" alt @click="showMore">
      <!--      <van-button type="primary" @click="send">-->
      <!--        发送-->
      <!--      </van-button>-->
    </div>
    <div v-if="visible" class="more">
      <upload-image upload-type="album" :fzlsh="fzlsh" v-on="$listeners" />
      <!--      <upload-image upload-type="camera" v-on="$listeners" />-->
    </div>
  </div>
</template>

<script>
  import UploadImage from './UploadImage'
  import {MessageType} from '../../utils/constant'
  export default {
    name: 'ChatInput',
    components: {
      UploadImage
    },
    props:{
      fzlsh:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        value: '',
        visible: false
      }
    },

    methods: {
      send() {
        const value = this.value.trim()
        if (!value) {
          return this.$toast('消息不能为空')
        }
        // console.log(value,'value')
        this.$emit('send-message', {
          msg: value,
          type: MessageType.TEXT,
          fzlsh:this.fzlsh
        })
        this.value = ''
      },
      showMore() {
        this.visible = true
        this.$emit('show')
      },
      hideMore() {
        this.visible = false
        this.$emit('hide')
      },
      onFocus(event) {
        this.hideMore()
        this.$emit('focus', event)
      },
      onBlur(event) {
        this.$emit('blur', event)
      }
    }
  }
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
.chat-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: @white-color;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

  .input-container {
    display: flex;
    align-items: center;
    padding: 12px 32px;
    background: @white-color;
    width: 90%;

    /deep/ .van-field {
      margin-right: 12px;
      background-color: #f5f5f5;
      border-radius :12px;

      &::after {
        border-bottom: none;
      }
    }

    .img {
      width: 54px;
      flex: 0 0 54px;
      height: 54px;
    }
  }
  /deep/.van-button{
    width: 88px;
    flex:0 0 88px;
    height: 56px;
    margin-left:12px;
    padding : 0;
    border-radius:28px;
    font-size:@font-size-26;
  }
  .more {
    display: flex;
    width: 100%;
    background-color: @white-color;
  }
}
</style>
