<!--
 * @Author: your name
 * @Date: 2021-03-05 11:30:08
 * @LastEditTime: 2021-03-05 13:31:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\chat\UploadImage.vue
-->
<template>
  <div class="upload-container">
    <div class="img-wrap" @click="clickInput">
      <img class="icon" :src="iconUrl" alt>
      <input id="file" ref="input" type="file" accept="image/*" class="hide" @change="openCamera">
    </div>
    <div class="text">
      {{ label }}
    </div>
  </div>
</template>

<script>
  import { MessageType } from '../../utils/constant'

  const ALBUM_TYPE = 'album'
  const CAMERA_TYPE = 'camera'

  export default {
    name: 'UploadImage',
    props: {
      // 类型：album：相册，camera：拍照
      uploadType: {
        type: String,
        default: ALBUM_TYPE
      },
      fzlsh:{
        type:String,
        default: ''
      }
    },
    computed: {
      label() {
        return this.uploadType === ALBUM_TYPE ? '相册' : '拍照'
      },
      iconUrl() {
        return require(`./images/${ this.uploadType }.png`)
      }
    },
    mounted() {
      this.input = this.$refs.input
      if (this.uploadType === CAMERA_TYPE) {
        this.input.setAttribute('capture', 'camera')
      }

    },
    methods: {
      openCamera(event) {
        this.$emit('send-message', {
          msg: event.target, type: MessageType.IMAGE,fzlsh:this.fzlsh
        })
      },
      clickInput() {
        if (this.input) {
          this.input.value = null
          this.input.click()
        }
      }
    }
  }
</script>
<style scoped lang="less">
@import "../../assets/styles/variable.less";
.upload-container {
  width: 108px;
  margin: 40px;
  text-align: center;
  .img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 108px;
    background-color: @color-F5F5F5;
    border-radius: 16px;

    .icon {
      width: 50px;
      height: 38px;
    }

    .hide {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      opacity: 0;
      cursor: inherit;
      display: none;
    }
  }

  .text {
    margin-top: 30px;
    color: #6a6d78;
    font-size: @font-size-24;
  }
}
</style>
