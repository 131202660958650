<!--
 * @Author: your name
 * @Date: 2021-03-05 09:54:46
 * @LastEditTime: 2023-02-09 16:00:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\views\chat\Chat.vue
-->
<template>
  <div ref="container" class="chat-page">
    <div v-if="waitStaus" class="patient">
      <span> 您前面还有{{ waitNumber }}位患者在排队，请您耐心等待！</span>
    </div>
    <div ref="msgWrap" :class="[
      'msg-list',
      { 'msg-top': waitStaus },
      { 'large-bottom': needMoreBottom },
      { 'none-bottom': !finishStaus },
    ]"
    >
      <message v-if="!signStaus && showStaus" :id="id" :current-msg-list="currentMsgList" :sureloading="sureloading" :username="username" @click-msg="onClickMsg" @click-list="onClickList" @surePrescription="sure" />
    </div>
    <template v-if="(finishStaus && showStaus)">
      <chat-input ref="chatInput" :fzlsh="fzlsh" @show="onShow" @hide="onHide" @focus="onFocus" @send-message="sendMessage" />
    </template>
    <div v-if="signStaus" class="sign-in" @click="siginIn">
      <div>候诊</div>
      签到
    </div>
    <!--    <div class="popup-prompt text-center" v-if='isShow'>-->
    <!--      过号已签到！-->
    <!--      <br />当前叫号序列上顺延3位！-->
    <!--    </div>-->
    <van-popup v-model="evaluateStaus" position="bottom" :style="{ height: '50%' }">
      <div class="evaluate">
        <div class="title">
          请对本次医生咨询进行评价
        </div>
        <van-rate v-model="value" allow-half :size="25" color="#ffd21e" void-icon="star" void-color="#eee" @change="onChange" />
        <van-field v-model="remark" name="remark" rows="5" autosize input-align="left" type="textarea" maxlength="200" placeholder="请填写评价语" show-word-limit />
        <div class="sumbit" @click="sumbit">
          提交
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
import Message from '../../components/chat/Message'
import ChatInput from '../../components/chat/ChatInput'
import { MessageType } from '../../utils/constant'
import { mapGetters, mapMutations } from 'vuex'
import * as mutationTypes from '@/store/mutation-types'
import { initChat } from '@/im/chat'
import { userModel } from '../../api/user'
import Stomp from 'stompjs'
//import { getItem } from '../../utils/cache'
import { appointModel } from '../../api/appoint'
import { getWsDomain } from '../../utils/http'


export default {
  components: {
    Message,
    ChatInput
  },
  data() {
    return {
      chatModel: null,
      remark: '',
      finished: false,
      // isShow:false,
      value: 0,
      needMoreBottom: false,
      // wenzhen: {},
      unSendMsgList: [],
      hasUnSendMsg: false,
      signStaus: false,//签到状态
      finishStaus: true,//结束状态
      showStaus: false,//签到状态
      sureloading: false,// 处方确认按钮
      sureStaus: false,// 处方确认状态
      waitStaus: true,//排队显隐
      evaluateStaus: false,//评价状态
      toUsername: '',
      waitNumber: 0,
      // 滚动到底部的间隔时间
      updateTime: 20,
      id: '',//预约单id,
      patientId: '',
      wsClient: '',
      hyid: '',
      username: '',
      fzlsh: ''

    }
  },
  computed: {
    ...mapGetters({
      // username: 'phoneNum',
      isImLogin: 'chat/isImLogin',
      chatId: 'chat/chatId',
      currentMsgList: 'chat/currentMsgList',
      isFinishedCurrentConsult: 'chat/isFinishedCurrentConsult'

    })
  },
  watch: {
    // 监听登录状态，重发消息
    isImLogin(newVal) {
      if (newVal && this.hasUnSendMsg) {
        // 重置标识
        this.hasUnSendMsg = false
        this._initChatModel()
      }
    },
    currentMsgList(newVal) {
      if (newVal && newVal.length) {
        //接收到消息时，判断是否还处于等待页面，如果是则刷新页面
        if (this.waitStaus) {
          this.getHuanxinUsername()
          this.finishStaus = true
          this.showStaus = true
          this.waitStaus = false
        }
        // 更新消息列表后更新页面高度，并滚动到最后一条消息
        this._updateHeight(this.updateTime)
        // 登录状态更新消息已读状态
        if (this.isImLogin) {
          this._initChatModel()
          this.updateReadStatus(this.chatModel)
        }
      }
    },
    isFinishedCurrentConsult(newVal) {
      // 更改路由中的结束状态，保证刷新时状态是最新的
      console.log(newVal, "监听结束语句")
      if (newVal) {
        this.evaluateStaus = true
      }
      if (newVal !== this.finished) {
        const query = this.$route.query
        this.finished = newVal
        this.$router.replace({ query: { ...query, finished: newVal } })
      }
    }
    // toUsername() {
    //   this._initStatus()
    // }
  },
  async mounted() {
    console.log(this.currentMsgList, "currentMsgList")
    const { id, hyid, username, fzlsh, patientId } = this.$route.query
    console.log(this.$route.query, 'this.$route.query')
    this.id = id
    this.patientId = patientId
    this.hyid = hyid
    this.fzlsh = fzlsh
    this.username = username
    this.getAppointmentDetail()
    this.getHuanxinUsername()
    //this.getHuanxinUsername()
    this.finished = this.$route.query.hzzt === '1'

    //this.getStart()
    this.wsClient = Stomp.client(getWsDomain())
    this.wsClient.connect({ token: this.$store.getters.token }, () => {
      console.log("执行webscoket")
      this.getStart()
      this.getOverSign()
      //this.getSgin()
    })

    // const res = await wsClient.subscribe(`/user/${getItem('PatentId')}/${hyid}/start`)
    //    console.log(res,"rqr")
    // this.wenzhen = JSON.parse(localStorage.getItem('wenzhen'))

    //this.toUsername = '13818071132'

  },
  methods: {
    sure() {
      if (!this.sureStaus) {
        this.sureloading = true
        this.chatModel.sendTextMessage({
          msg: '确认以上药品信息无误!',
          fzlsh: this.fzlsh
        }).then(() => {
          this.sureStaus = true
        }).finally(() => {
          this.sureloading = false
        })
      }
    },
    onChange(value) {
      console.log(value, "vakye")
      this.value = value
    },
    sumbit() {
      const pj = this.remark.trim()
      if (pj) {
        userModel.write({ appointId: this.id, pjfz: this.value, pj }).then(res => {
          console.log(res)
          this.$toast('评价成功!')
          this.evaluateStaus = false
          this.$router.push({
            path: '/'
          })


        }).catch(error => {
          this.$toast(error.msg)
        })//appointId预约单id pjf评价分值  pj评价语句
      } else {
        this.$toast('请填写评价内容!')
      }

    },
    async getHuanxinUsername() {
      const { data } = await userModel.doctorByuser({ yyghdid: this.$route.query.id })
      this.toUsername = data && data.username || ''
      await this._initStatus()
    },
    //查询预约挂号详情信息
    getAppointmentDetail() {
      appointModel.appointmentDetail({ appointId: this.id }).then(res => {
        console.log(res, 'reseqwqwewqs')
        const { qdxh, hzzt } = res.data
        if (qdxh === '0' || qdxh === '2') {//签到状态
          this.signStaus = true//签到显示
          this.showStaus = false
          this.finishStaus = false
        } else {
          this.getAleady()
          this.signStaus = false
        }
        if (hzzt == '0' && qdxh == '1') {
          this.waitStaus = true//剩余人
          this.finishStaus = false
          // this.showStaus=true
        } else {
          this.waitStaus = false//剩余人数
          //this.showStaus=true
        }
        if (hzzt == '2' && qdxh == '1') {
          this.showStaus = true
          this.finishStaus = true
        }
        if (res.data.hzzt == '1') {
          this.getMyEvaluate()
          this.showStaus = true
          this.finishStaus = false
        } else {
          this.evaluateStaus = false
        }
      })
    },
    //监听叫号
    async getStart() {

      this.wsClient.subscribe(`/user/${this.patientId}/${this.hyid}/start`, (res) => {
        console.log(res, '叫号')
        this.getHuanxinUsername()
        this.finishStaus = true
        this.showStaus = true
        this.waitStaus = false

      })

    },
    //监听过号  `/user/${this.list.patientinfoMsg==undefined?uni.getStorageSync('piatentID'):this.list.patientinfoMsg.id}/${this.list.hyid}/overSign`,
    async getOverSign() {
      this.wsClient.subscribe(`/user/${this.patientId}/${this.hyid}/overSign`, (res) => {
        console.log(res, '过号')
        this.getAppointmentDetail()
        this.showStaus = false
      })

    },
    //监听结束 `/user/${this.list.patientinfoMsg==undefined?uni.getStorageSync('piatentID'):this.list.patientinfoMsg.id}/${this.list.hyid}/end`,
    async getEnd() {
      this.wsClient.subscribe(`/user/${this.patientId}/${this.hyid}/end`, (res) => {
        console.log(res, '结束')
        this.finishStaus = false
        this.getAppointmentDetail()
      })

    },
    //监听签到`/topic/consult/${this.list.hyid}`,
    async getSgin() {
      this.wsClient.subscribe(`/topic/consult/${this.hyid}`, (res) => {
        console.log(res, '签到')

        this.getAppointmentDetail()
        this.showStaus = false

      })

    },
    //查询评价信息
    getMyEvaluate() {
      userModel.myEvaluate({ yyghdid: this.id }).then(res => {
        if (res.data.pjfz) {
          this.evaluateStaus = false
        } else {
          this.evaluateStaus = true
        }
      })
    },
    //获取排队数量
    getAleady() {
      userModel.already({ appointId: this.id }).then(res => {
        console.log(res, 'r')
        this.waitNumber = res.data
      })
    },
    // msg是消息内容，不是整个消息对象
    sendMessage({ msg, type, fzlsh, url }) {
      console.log(this.showStaus, '状态')
      console.log(this.isImLogin, '登录')
      this._initChatModel()
      console.log(this.chatModel, this.isImLogin)
      if (this.isImLogin) {
        this.updateTime = type === MessageType.IMAGE ? 500 : 20
        this.chatModel.sendMessage(type, { msg, fzlsh, url })
        return
      }



      // 处理发消息时未登录的情况,当前组件待发送消息标识为false时登录环信，否则标识登录过了，防止重复登录
      // if (!this.hasUnSendMsg) {
      //   this.chatModel.login()
      //   this.hasUnSendMsg = true
      // }
      // this.chatModel.saveWaitSendMessage(msg, type, url)
    },
    _initChatModel() {
      console.log(this.username, this.toUsername, '213s')
      if (!this.chatModel) {
        this.chatModel = initChat(this.username, this.toUsername)
      }
    },
    _updateHeight(time = 20) {
      setTimeout(() => {
        const msgWrap = this.$refs.msgWrap
        if (!msgWrap) {
          return
        }

        msgWrap.scrollTo(0, msgWrap.scrollHeight)
      }, time)
    },
    async _initStatus() {
      if (this.toUsername) {
        this.setChatId(`${this.username}_${this.toUsername}`)
        // this.setFinishStatus({
        //   chatId: this.chatId,
        //   finished: this.finished
        // })
        this._initChatModel()
        // 未登录环信，登录环信
        if (!this.isImLogin) {
          await this.chatModel.login()
        }
      }
    },

    onClickMsg({ type, mid, ext, msg }) {
      console.log('parent click msg', type, msg)
      if (type === MessageType.IMAGE) {
        let index = 0
        const images = this.currentMsgList.filter(msg => msg.type === MessageType.IMAGE).map((msg, idx) => {
          if (msg.mid === mid) {
            index = idx
          }

          return msg.msg || msg.url
        })
        ImagePreview({
          images,
          startPosition: index
        })
      } else if (type === MessageType.TEXT) {
        if (ext && ext.isPrescription) {
          this.$router.push(`/prescription/${msg}`)
        }
      }
    },
    onClickList() {
      // 点击消息列表，发送图片的图标展示时，将其隐藏
      const chatInput = this.$refs.chatInput
      if (chatInput.visible) {
        chatInput.hideMore()
      }
    },
    siginIn() {
      userModel.waitSign({ appointId: this.id }).then(res => {
        console.log(res)
        this.waitStaus = true
        this.signStaus = false
        this.getAleady()
      }).catch(error => {
        this.$toast(error.msg)
      })
    },
    onFocus() {
      this._updateHeight(300)
    },
    onHide() {
      this.needMoreBottom = false
      this._updateHeight()
    },
    onShow() {
      this.needMoreBottom = true
      this._updateHeight()
    },
    ...mapMutations({
      setChatId: mutationTypes.getMutationType(mutationTypes.SET_CHAT_ID),
      updateReadStatus: mutationTypes.getMutationType(mutationTypes.UPDATE_READ_STATUS),
      setFinishStatus: mutationTypes.getMutationType(mutationTypes.SET_FINISH_STATUS)
    })

  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.chat-page {
  .patient {
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    height: 88px;
    line-height: 88px;
    background: rgba(20, 133, 255, 0.08);
    color: #2a3740;
    font-size: 28px;
    padding-left: 40px;
    z-index: 1000;

    .state {
      color: @theme-color;
      font-size: @font-size-24;
      margin-right: 20px;

      &::before {
        content: "";
        position: absolute;
        left: 24px;
        top: 50%;
        width: 8px;
        height: 8px;
        background: @theme-color;
      }
    }

    .time {
      color: @red-color;
    }
  }

  .msg-top {
    top: 180px !important;
  }

  .msg-list {
    position: fixed;
    top: 90px;
    bottom: 112px;
    left: 0;
    right: 0;
    overflow-y: auto;
    background-color: @color-F5F5F5;
    z-index: 999;

    &.top {
      top: 178px;
    }

    &.large-bottom {
      bottom: 356px;
    }

    &.none-bottom {
      bottom: 0;
    }
  }

  .finish {
    position: absolute;
    bottom: 520px;
    right: 32px;
    z-index: 1000;

    .img {
      width: 112px;
      height: 112px;
    }
  }

  .sign-in {
    position: fixed;
    left: 50%;
    bottom: 178px;
    z-index: 1000;
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    transform: translateX(-50%);
    background: linear-gradient(180deg,
        rgba(115, 198, 253, 1) 0%,
        rgba(28, 156, 246, 1) 100%);
    box-shadow: 0 6px 10px 0 rgba(28, 156, 246, 0.4);
    border-radius: 50%;
  }

  .evaluate {
    text-align: center;
    padding: 32px 32px 0 32px;

    .title {
      margin-bottom: 93px;
    }

    .sumbit {
      background: #5082ff;
      z-index: 10000;
      height: 80px;
      line-height: 80px;
      position: absolute;
      bottom: 10px;
      width: 90%;
      border-radius: 6px;
      color: #fff;
    }
  }
}
</style>
