<!--
 * @Author: your name
 * @Date: 2021-03-05 19:35:12
 * @LastEditTime: 2021-03-15 20:52:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\chat\Prescription.vue
-->
<template>
  <div :class="['prescription-card', { 'self': message.bySelf }]" @click="goPage">
    <div class="top flex" @click.stop="sure">
      <div class="left flex">
        <img src="./images/dir.png" class="dir-img" alt="">
        <span class="text">药品信息</span>
        <van-icon class-prefix="custom-icon" name="right" />
      </div>
      <div class="right flex">
        <van-button round type="info" :loading="sureloading">
          确定
        </van-button>
      </div>
    </div>
    <div class="border" />
    <div v-for="(item,index) in data" :key="index">
      <div class="item flex">
        药品名： {{ item.xmbzmc }}
      </div>
      <div class="item flex">
        规格：{{ item.gg }}
      </div>
      <div class="item flex">
        数量：{{ item.fysl }}({{ item.ftdw }})
      </div>
      <div class="item flex">
        单价：¥{{ item.dj }}
      </div>
      <div class="item flex">
        用法用量：{{ item.yfmc }}，每次{{ item.dcjl }}{{ item.dcjldw }}，{{ item.pcmc }}，用药{{ item.yyts }}天
      </div>
    </div>

    <!--    <div class="item flex">-->
    <!--      <div class="label">-->
    <!--        处方涉药：-->
    <!--      </div>-->
    <!--      <div class="value">-->
    <!--        散利痛、帕罗西汀-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {userModel} from '../../api/user'
  export default {
    name: 'PrescriptionCard',
    props: {
      data: {
        type: Array,
        default: () => ([])
      },
      message:{
        type:Object,
        default:()=>({})
      },
      sureloading:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        dataSource: {},
        zjhm:'',
          id:''
      }
    },
    mounted(){
      // const {msg}=this.message
      // const arr = msg.split('-')
      // if (arr && arr.length) {
      //  this.getList(arr[0], arr[1])
      //  this.zjhm=arr[1]
      //  this.id=arr[0]
      // }


    },
    methods:{
      sure(){
        this.$emit('sure')
      },
      goPage(){
        this.$router.push({
          path: '/inReview',
          query: {
            zjhm:this.zjhm,
            id:this.id
          }
        })

      },
      getList(id, zjhm){
      let params={
        zjhm:zjhm,
        prescriptionId:id
      }
        userModel.prescriptionList(params).then(res=>{
          this.dataSource=res.data[0]||[]
        })
      }
    }

  }
</script>

<style lang="less" scoped>
@import "../../assets/styles/variable.less";

.prescription-card {
  width: 100%;
  padding: 24px 20px;
  line-height: 44px;
  background-color: @white-color;
  font-size: @font-size-32;
  color: @dark-font-color;
  border-radius: 14px 0 14px 14px;

  &.self {
    background-color: #DFE7FB;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .top {
    justify-content: space-between;
    .left {
      .dir-img {
        width: 60px;
        height: 50px;
      }

      .text {
        margin: 0 18px 0 28px;
        color:#333333;
        font-weight: bold;
      }

      .custom-icon-right {
        font-size: @font-size-28;
      }
    }

    .right {
      padding: 6px 16px;
      border-radius: 12px;
      color: @theme-color;
      background-color: @white-color;

      .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: @theme-color;
      }

      .text {
        margin-left: 12px;
        font-size: @font-size-28;
      }

      &.auditing {
        color: #FF3B30;

        .dot {
          background-color: #FF3B30;
        }
      }
    }
  }
  .border{
    border-top:1px solid #DDDDDD;
    margin-top:15px;
  }
  .item {
    margin-top: 24px;
    font-size: 24px;

    .label {
      width: 100px;
      flex: 0 0 100px;
    }
  }
  /deep/.van-button--round{
    width:100% !important;
    height:60px;
  }
}
</style>
