<!--
 * @Author: your name
 * @Date: 2021-03-05 10:18:13
 * @LastEditTime: 2021-03-15 20:48:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\chat\Message.vue
-->
<template>
  <div>
    <template v-for="(item,index) of currentMsgList">
      <div :key="index" :class="['msg-container', {'self': item.bySelf}]" @click="onClick">
        <div v-if="item.showTime" class="time">
          {{ item.showTime }}
        </div>
        <div class="avatar-wrap">
          <img :src="item.bySelf ? selfAvatar : doctorAvatar" alt>
        </div>
        <div v-if="item.msg" class="msg" @click.stop="onClickMsg(item)">
          <div class="msg-content">
            <div
              v-if="item.bySelf"
              class="tip"
            >
              {{ item.status === messageStatus.READ ? '已读' : '未读' }}
            </div>
            <!--            <prescription-card v-if="item.ext && item.ext.isPrescription" :message="item" />-->
            <!--            <drugItem v-if="item.ext && item.ext.medicines && item.ext.medicines.length" :data="item.ext.medicines" :show-button="showButton" @sure="sure" />-->
            <prescription-card v-if="item.ext && item.ext.medicines && item.ext.medicines.length" :data="item.ext.medicines" :sureloading="sureloading" @sure="sure" />
            <div
              v-else-if="item.ext && item.ext.confrId"
              class="text video"
              @click="joinVideo(item)"
            >
              <div class="left">
                <img :src="require('../../assets/img/video.png')" alt>
              </div>
              <div class="right">
                <div class="text">
                  {{ item.msg }}
                </div>

                <van-button plain round type="info" size="small">
                  加入
                </van-button>
              </div>
            </div>
            <!-- 显示消息的div不要换行，css使用了white-space：pre-line会保留换行符   -->
            <!--   eslint-disable    -->
            <div v-else-if="item.type === textType" class="text">{{ item.msg }}</div>
            <div v-else-if="item.type === imgType">
              <img class="msg-img" :src="item.url || item.msg" alt />
            </div>
          </div>
        </div>
      </div>
    </template>


  </div>
</template>
<script>
import myAvatar from './images/avatar.png'
import defaultDoctorAvatar from './images/doctor-avatar.png'
import { MessageType, MessageStatus } from '../../utils/constant'
import PrescriptionCard from './Prescription'

export default {
  components: {
    PrescriptionCard
  },
  props: {
    currentMsgList: {
      type: Array,
      default: () => ([])
    },
    id:{
      type:String,
      default:''
    },
    username:{
      type:String,
      default:''
    },
    sureloading:{
      type:Boolean,
      default:false
    }

  },
  data() {
    return {
      imgType: MessageType.IMAGE,
      textType: MessageType.TEXT,
      show: true,
      // isShow:false,
      showButton:true,
      selfAvatar: myAvatar,
      doctorAvatar: defaultDoctorAvatar

    }
  },
  computed: {
    // avatar() {
    //   const selfAvatar = myAvatar
    //   const doctorAvatar = defaultDoctorAvatar
    //   return this.bySelf ? selfAvatar : doctorAvatar
    // },
    messageStatus() {
      return MessageStatus
    }
  },
  mounted() {
  },
  methods: {
    onClick() {
      this.$emit('click-list')
    },
    joinVideo(item){
      this.$router.push({
        path:'/video-chat/' + item.ext.confrId,
        query:{
          username:this.username
        }
      })
      console.dir("查收")
    },
    onClickMsg(message) {
      this.$emit('click-msg', message)
    },


    sure(){
      this.$emit('surePrescription')
    }

  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

.msg-container {
  float: left;
  width: 100%;
  padding: 30px 40px;
  box-sizing: border-box;

  &.self {
    float: right;

    .avatar-wrap {
      float: right;
      margin: 0 0 0 20px;
    }

    .msg {
      float: right;

      .username {
        text-align: right !important;
      }

      .text {
        background-color: #dfe7fb !important;
        border-radius: 28px 0 28px 28px !important;
      }
    }
  }

  .time {
    line-height: 32px;
    text-align: center;
    font-size: @font-size-24;
    color: @dark-font-color;
  }

  .avatar-wrap {
    float: left;
    display: inline-block;
    width: 96px;
    height: 96px;
    vertical-align: top;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .msg {
    position: relative;
    display: inline-block;
    max-width: 80%;
    vertical-align: top;
    float: left;

    .msg-content {
      display: flex;
      align-items: flex-end;
      margin-top: 24px;

      .tip {
        width: 64px;
        flex: 0 0 64px;
        margin-right: 10px;
        text-align: right;
        line-height: 32px;
        font-size: @font-size-24;
        color: @middle-font-color;

        .err-img {
          width: 32px;
          height: 32px;
        }
      }

      .text {
        flex: 1;
        padding: 20px;
        line-height: 36px;
        background: #ffffff;
        border-radius: 0 28px 28px 28px;
        font-size: @font-size-28;
        color: @dark-font-color;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-line;
        overflow: hidden;
      }
      .video {
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        .left{
          width:100px;
          height: 100px;
          padding-right:12px;
          img{
            width:100%;
            height: 100%;
          }
        }
        .right{
          text-align: right;
          .text{
             margin-bottom:7px;
          }

        }
        .joinButoon {

        }
      }
      /deep/.van-button--round {
        width: 60%;
      }
      .msg-img {
        max-width: 400px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}


</style>
